import { Actions, ActionsState } from 'components/common/Actions'
import { AuthActions } from 'components/common/AuthActions'
import { Logo } from 'components/common/Logo'
import { MobileMenu } from 'components/common/MobileMenu'
import { NavMenu } from 'components/nav/NavMenu'
import { Separator } from 'components/common/Separator'
import { StaticIcon } from 'components/common/StaticIcon'
import useTranslation from 'next-translate/useTranslation'
import { useDrawer } from '../../contexts'

export type HeaderProps = {
  className?: string
  hasNav?: boolean
  actions?: ActionsState
  hasSeparator?: boolean
  offWhite?: boolean
}

export const Header = ({
  className,
  hasNav,
  actions,
  hasSeparator = false,
  offWhite = false,
}: HeaderProps) => {
  const { t } = useTranslation('common')
  const { setIsOpen, setConfig } = useDrawer()

  return (
    <>
      <header
        className={`container hidden h-16 items-center lg:flex lg:h-20 ${
          actions?.includes('faq') && 'justify-between'
        } ${className}`}
      >
        <Logo />

        {actions && <Actions state={actions} offWhite={offWhite} />}

        {hasNav && <NavMenu className='mr-2 hidden lg:flex' />}

        {actions === 'auth' && <AuthActions />}
      </header>

      <header
        className={`container flex h-16 items-center justify-between lg:hidden ${
          actions?.includes('faq') && 'justify-between'
        } ${className}`}
      >
        {hasNav && (
          <button
            type='button'
            className='flex'
            data-e2e='main-side-menu-button'
          >
            <span className='sr-only'>{t('toggle_navigation_menu')}</span>

            <StaticIcon
              name='hamburgerMenu'
              className='cursor-pointer text-cool-500'
              size={25}
              onClick={() => {
                setConfig?.({
                  direction: 'left',
                  heading: <Logo />,
                  body: (
                    <MobileMenu
                      actions={actions === 'auth' && <AuthActions isMobile />}
                    />
                  ),
                })
                setIsOpen(true)
              }}
            />
          </button>
        )}

        <Logo />

        {actions && <Actions state={actions} offWhite={offWhite} />}
      </header>

      {hasSeparator && <Separator color='cool-300' />}
    </>
  )
}
